import { AxiosResponse } from 'axios';
import axios from './axios';
import { ContactUsRequest } from './interfaces';

export const internal = {
  /**
   * Contactus
   * @param payload
   */
  contactUs: async (payload: ContactUsRequest) => {
    const response: AxiosResponse = await axios.post(
      '/Notifications/ContactUs',
      payload,
      {
        headers: {
          'Api-Version': 1
        }
      }
    );

    return response.data;
  }
};
